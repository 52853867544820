/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { AuditEntry } from 'src/app/model/auditEntry';
import { catchError, map, tap } from 'rxjs/operators';
import { AbstractObject, AbstractObjectList, uuid } from '../model/abstract-object';
import { Attachment } from '../model/attachment';
import { Field } from './field/Field';
import { Follower } from '../model/user';
import { ChatItem } from '../model/chat-item';
import { HttpErrorHandler } from './HttpErrorHandler';
import { Params } from '@angular/router';
import { Memo } from '../model/memo';
import { MyInjector } from '../app.module';
import { OurErrorHandlerService } from './our-error-handler-service';

export type KnownError = {
    status: number;
    code: string;
    statusText: string;
    msg: MsgDef;
};

export type EscalationResponse = { added: Follower[], members: number, alreadyHad: number }
export abstract class AbstractHttpService {
    /*
      readonly ajaxPath = wpApiSettings.root; // . environment.ajaxPath;
      readonly siteSlug = wpApiSettings.wpurl.substr(wpApiSettings.wpurl.lastIndexOf('/'));
    */
    static readonly ajaxPath = '/api/api/';

    errorHandler = new HttpErrorHandler();

    readonly ajaxPath = AbstractHttpService.ajaxPath;
    httpOptions = {
        headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    protected hasData = false;
    protected msgUpdated: MsgDef = new MsgDef($localize`Record has been saved`);
    protected msgAdded: MsgDef = new MsgDef($localize`New record has been added`);
    protected msgDeleted: MsgDef = new MsgDef($localize`Record has been deleted`);

    protected abstract baseUrl: string;

    protected dummyService = false;

    protected gettingCache = false;
    protected abstract cache: AbstractObject[];
    protected cacheRequest: Observable<AbstractObject[]>

    protected abstract typeString: string;

    protected abstract http: HttpClient;
    protected abstract messageService: MessageService;

    protected hasRevision = true;

    protected enhanceList(items: AbstractObject[]) {
        return items;
    }

    getBaseUrl() {
        return this.baseUrl;
    }

    getAttachmentUrl(id: uuid): string {
        return this.baseUrl + '/attach/' + id;
    }

    getCommentUrl(id: uuid): string {
        return this.baseUrl + '/comment/' + id;
    }

    getMemoUrl(id: uuid): string {
        return this.baseUrl + '/memo/' + id;
    }

    getOne<AbstractObject>(id: uuid, badReturns: AbstractObject, forceTeamId: uuid = null): Observable<AbstractObject> {
        let httpParams = new HttpParams();
        if (forceTeamId) {
            httpParams = httpParams.set('_forceTeam', forceTeamId)
        }

        return this.http.get<AbstractObject>(this.baseUrl + '/' + id, { params: httpParams })
            .pipe(
                catchError(this.handleOneError<AbstractObject>('get' + this.typeString, badReturns))
            );
    }

    getAuditItemTrail(id: uuid): Observable<AuditEntry[]> {
        return this.http.get<AuditEntry[]>(this.baseUrl + '/' + id + '/audit')
            .pipe(
                catchError(this.handleOneError<AuditEntry[]>('getAudit' + this.typeString, []))
            );
    }

    getAuditListTrail(): Observable<AuditEntry[]> {
        return this.http.get<AuditEntry[]>(this.baseUrl + '/audit')
            .pipe(
                catchError(this.handleOneError<AuditEntry[]>('getAudit' + this.typeString, []))
            );
    }

    filterCache(params: HttpParams): AbstractObject[] {
        const returnAry = [];
        this.cache.forEach(item => {
            let include = true;
            params.keys().forEach(key => {
                let fValue = params.get(key) as string;
                fValue = fValue.replace('%', '');

                if (!(item[key] as string).includes(fValue)) {
                    include = false;
                }
            })
            if (include) {
                returnAry.push(item);
            }
        })
        return returnAry; //this.cache;
    }

    get<T extends AbstractObject>(useCache = false, params: HttpParams = null, forceTeamId: uuid = null): Observable<T[]> {
        if (forceTeamId) {
            if (!params) {
                params = new HttpParams();
            }
            params = params.set('_forceTeam', forceTeamId);
            useCache = false;
        }

        if (this.dummyService && this.cache && params) {
            return of(this.filterCache(params) as T[]);
        }
        if (useCache && params) {
            console.error('Error accessing ' + this.baseUrl + ' - Not able to use cache with parameter yet...');
        }
        if (this.hasData && useCache && params === null) {
            return of(this.cache as T[]);
        } else if (useCache && this.gettingCache && this.cacheRequest) {
            // Doesn't work - it executes the XHR every time...
            return this.cacheRequest as Observable<T[]>;
        } else {
            if (useCache && params == null) {
                this.gettingCache = true;
            }

            this.cacheRequest = this.http.get<T[]>(this.baseUrl, { params })
                .pipe(
                    map((data: T[]) => {
                        const freshData = this.enhanceList(data);
                        if (useCache && params === null) { //&& params.keys.length === 0) {
                            this.cache = freshData;
                            this.gettingCache = false;
                            this.hasData = true;
                        }
                        return freshData as T[];
                    }),
                    catchError(this.handleError<T[]>('get' + this.typeString, []))
                );
            return this.cacheRequest as Observable<T[]>;
        }
    }

    postItems(items: AbstractObject[], showMsg = true): Observable<AbstractObjectList> {
        return this.http.post<AbstractObjectList>(this.baseUrl, { isList: true, itemList: items }, this.httpOptions)
            .pipe(
                map((data: AbstractObjectList) => {
                    if (this.hasData) {
                        this.hasData = false;
                    }
                    if (showMsg) {
                        this.messageService.show(this.msgAdded);
                    }
                    return data as AbstractObjectList;
                }),
                catchError((error: HttpErrorResponse): Observable<AbstractObjectList> => {
                    console.error(this.typeString, error); // log to console instead
                    this.messageService.show(this.errorHandler.getErrorMessage(error, 'Batch Post ' + this.typeString));
                    return of({ itemList: [] });
                })
            );
    }

    post<T extends AbstractObject>(object: T, showMsg = true, teamId = null): Observable<T> {
        const attachments = object['attachments'];
        const options = this.getSimpleOptions(teamId);
        const postObject: AbstractObject | FormData = object;
        let attachNeeded = []
        if (attachments && Array.isArray(attachments) && attachments[0] instanceof File) {
            attachNeeded = object['attachments'].slice();
            object['attachments'].length = 0;
            /*options = this.getMultiPartOptions(teamId);
            const formData = new FormData();
            attachments.forEach(upf => formData.append('fileKey', upf, upf.name));
            delete object['attachments'];
            formData.append('itemJSON', new Blob([JSON.stringify(object)], {
                type: 'application/json'
            }), 'itemJSON');
            postObject = formData;
            */
        }
        return this.http.post<T>(this.baseUrl, postObject, options)
            .pipe(
                map((data: T) => {
                    console.log('These need to be added after event', attachNeeded);
                    attachNeeded.forEach( attach => {
                        this.attach(attach, data.id).subscribe( a => {
                            // n.b. if not logged, then XHR never fired (.subscribe not enough)
                            console.log('created', a);
                        })
                    })
                    if (this.hasData) {
                        this.cache.unshift(data);
                    }
                    return data;
                }),
                tap((data: T) => {
                    console.log('Added a new ' + this.typeString, data);
                    if (showMsg) {
                        this.messageService.show(this.msgAdded);
                    }
                }),
                catchError(this.handleError<T>('post' + this.typeString))
            );
    }

    getSimpleOptions(forceTeamId: uuid, asJson = true) {
        let params = new HttpParams();
        if (forceTeamId) {
            params = params.set('_forceTeam', forceTeamId)
        }
        if (asJson) {
            return {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                params
            };
        } else {
            return {
                params
            };
        }
    }

    updateCachedItem(data: AbstractObject) {
        if (data?.id && this.hasData && this.cache) {
            this.cache.forEach((obj, ndx) => {
                if (obj.id === data.id) {
                    this.cache[ndx] = data;
                }
            });
        }
    }

    put<T extends AbstractObject>(object: T, forceTeamId: uuid = null): Observable<T> {

        const url = this.baseUrl + '/' + object.id + (this.hasRevision ? '/' + object.revision : '');
        if (Field.isEmpty(object.id) || (this.hasRevision && Field.isEmpty(object.revision))) {
            console.error('Unable to save object without ID and/or revision', { object, form: this })
        }
        return this.http.put<T>(url, object, this.getSimpleOptions(forceTeamId))
            .pipe(
                map((data: T) => {
                    if (this.hasData) {
                        this.updateCachedItem(data);
                    }
                    return data;
                }),
                tap((data: T) => {
                    console.log('Updated a ' + this.typeString, data);
                    if (this.msgUpdated !== null) {
                        this.messageService.show(this.msgUpdated);
                    }
                }),
                catchError(this.handleError<T>('put' + this.typeString))
            );
    }

    delete<T extends AbstractObject>(object: T, forceTeamId: uuid = null, reason: string = null): Observable<boolean> {
        const url = `${this.baseUrl}/${object.id}/${object.revision}`;
        const opts = this.getSimpleOptions(forceTeamId);
        if (reason) {
            opts['body'] = { deleteReason: reason };
        }

        return this.http.delete<unknown>(url, opts)
            .pipe(
                map(() => {
                    /* Remove the deleted element in cache
                     * be careful not to use a method like filter that creates a new array
                     * Or references in components will be lost.
                    */
                    if (this.hasData) {
                        this.cache.forEach((obj, ndx) => {
                            if (obj.id === object.id) {
                                this.cache.splice(ndx, 1);
                            }
                        });
                    }
                    return true;
                }),
                tap(() => {
                    console.log(`Removed ${this.typeString} ID ${object.id}`);
                    this.messageService.show(this.msgDeleted);
                }),
                catchError(this.handleError<boolean>('delete' + this.typeString, false))
            );
    }

    stopFollowing(modelId: uuid, userId: uuid, showMsg = true, forceTeamId: uuid = null): Observable<boolean> {
        const url = this.baseUrl + '/follow/' + modelId + '/' + userId;
        return this.http.delete(url, this.getSimpleOptions(forceTeamId))
            .pipe(
                map((data: unknown) => {
                    console.log('Successfully remove follower', { modelId, userId, data });
                    if (showMsg) {
                        this.messageService.show($localize`Follower Removed `);
                    }
                    return true;
                }),
                catchError(this.handleOneError<boolean>('stopFollow-' + this.typeString, false))
            );
    }

    follow(modelId: uuid, userId: uuid, personId: uuid = null, forceTeam: uuid = null): Observable<Follower> {
        const postData = { userId };
        if (personId) {
            postData['personId'] = personId;
        }
        if (forceTeam) {
            postData['_forceTeam'] = forceTeam;
        }
        return this.http.post<Follower>(this.baseUrl + '/follow/' + modelId, postData, this.httpOptions)
            .pipe(
                map((data: Follower) => {
                    return data;
                }),
                tap(() => {
                    this.messageService.show($localize`Follower Added`)
                }),
                catchError(this.handleError<Follower>('follow-' + this.typeString))
            );
    }

    escalate(modelId: uuid, followers: Follower[], _forceTeam: uuid = null): Observable<EscalationResponse> {
        return this.http.post<EscalationResponse>(this.baseUrl + '/escalate/' + modelId, { _forceTeam })
            .pipe(
                map((data: EscalationResponse) => {
                    data.added.forEach(f => followers.push(f));
                    return data;
                }),
                tap((data: EscalationResponse) => {
                    if (data.added.length > 0) {
                        this.messageService.show($localize` ${data.added.length} users added, ${data.alreadyHad} where already following`)
                    } else if (data.members > 0) {
                        const m = new MsgDef($localize` No users added, ${data.alreadyHad} where already following`, 'warn');
                        this.messageService.show(m);
                    } else {
                        const m = new MsgDef($localize` There are no members in management forum to receive escalations`, 'fail');
                        this.messageService.show(m);
                    }

                }),
                catchError(this.handleError<EscalationResponse>('follow-' + this.typeString))
            );
    }

    postChatItem(object: Partial<ChatItem>, relatedId: uuid, showMsg = true, forceTeamId: uuid = null): Observable<ChatItem> {

        return this.http.post<ChatItem>(this.getCommentUrl(relatedId), object, this.getSimpleOptions(forceTeamId))
            .pipe(
                map((data: ChatItem) => {
                    return data;
                }),
                tap((data: ChatItem) => {
                    console.log('Added a new ' + this.typeString + '-chatItem', data);
                    if (showMsg) {
                        this.messageService.show($localize`New Comment Has Been Added To ${this.typeString}`);
                    }
                }),
                catchError(this.handleError<ChatItem>('post' + this.typeString + '-chatItem'))
            );
    }

    postMemo(memo: string, id: uuid, forceTeamId: uuid = null): Observable<Memo> {
        return this.http.post<Memo>(this.getMemoUrl(id), { memo }, this.getSimpleOptions(forceTeamId))
            .pipe(
                tap(() => {
                    this.messageService.show($localize`New Memo Has Been Added To ${this.typeString}`);
                }),
                catchError(this.handleError<Memo>('post' + this.typeString + '-memo'))
            );
    }

    putMemo(memo: Memo): Observable<Memo> {
        return this.http.put<Memo>(this.getMemoUrl(memo.relatedId), memo, this.getSimpleOptions(memo.teamId))
            .pipe(
                tap(() => {
                    this.messageService.show($localize`Memo Saved`);
                }),
                catchError(this.handleError<Memo>('post' + this.typeString + '-memo'))
            );
    }

    protected handleError<T>(operation = 'operation', result?: T) {
        return (error: HttpErrorResponse): Observable<T> => {

            console.error(this.typeString, error, result); // log to console instead


            if (error.status === 401) {
                window.location.href = "/login?returnUrl=" + encodeURIComponent(window.location.pathname);
            } else if (error.status === 503) {
                window.location.href = "https://clgsystems.ie/maintenance-mode?site=" + window.location.hostname + "&url=" + window.location.href;
            } else {
                if (error.status === 500) {
                    console.log('Gotta 500 report it');
                    const oehs = MyInjector.instance.get(OurErrorHandlerService);
                    oehs.logError(error);
                }
                this.messageService.show(this.errorHandler.getErrorMessage(error, operation));

                // TODO: better job of transforming error for user consumption
                console.error(`${operation} failed: ${error.message}`);

                // Let the app keep running by returning an empty result.
                return of(result as T);
            }
        };
    }

    protected handleOneError<AbstractObject>(operation = 'operation', badReturns: AbstractObject) {
        return (error: HttpErrorResponse): Observable<AbstractObject> => {

            console.error(this.typeString, error, badReturns); // log to console instead

            if (error.status === 401) {
                window.location.href = "/login?returnUrl=" + encodeURIComponent(window.location.pathname);
            } else if (error.status === 503) {
                window.location.href = "https://clgsystems.ie/maintenance-mode?site=" + window.location.hostname + "&url=" + window.location.href;
            } else {
                this.messageService.show(this.errorHandler.getErrorMessage(error, operation));

                // TODO: better job of transforming error for user consumption
                console.log(`${operation} failed: ${error.message}`);
            }

            // Let the app keep running by returning an empty result.
            return of(badReturns);
        };
    }

    attach(fileToUpload: File, modelId: uuid = 0, forceTeam: uuid = null, forceName: string = null): Observable<Attachment> {
        let params: Params = {};
        if (forceTeam) {
            params = { _forceTeam: forceTeam }
        }
        const endPoint = this.baseUrl + '/attach/' + modelId;
        const formData = new FormData();
        formData.append('fileKey', fileToUpload, forceName ? forceName : fileToUpload.name);

        return this.http.post<Attachment>(endPoint, formData, { params })
            .pipe(
                map((data: Attachment) => {
                    console.log(data);
                    return data;
                }),
                catchError(this.handleError<Attachment>('get' + this.typeString, null)
            ));
    }

    detach(attachment: Attachment): Observable<Attachment> {
        const endPoint = this.baseUrl + `/attach/${attachment.id}/${attachment.revision}`;

        return this.http.delete<unknown>(endPoint, this.httpOptions)
            .pipe(
                map(() => {
                    return true;
                }),
                catchError((e, caught) => {
                    console.log(e, caught);
                    this.messageService.show(this.errorHandler.getErrorMessage(e, 'Removing Attachment'));
                    return of(null);
                })
            );
    }
    getAttachments(): Observable<Attachment[]> {
        return this.http.get<Attachment[]>(this.baseUrl + '/attach', {})
            .pipe(
                map((data: Attachment[]) => {
                    return data;
                }),
                catchError(this.handleError<Attachment[]>('get' + this.typeString, [])
                ));
    }
    //Observable<HttpResponse<ArrayBuffer>>
    getAttachment(id: uuid, forceTeam: uuid = null): Observable<HttpResponse<ArrayBuffer>> {
        let params: Params = {};
        if (forceTeam) {
            params = { _forceTeam: forceTeam }
        }
        return this.http.get(this.baseUrl + '/attach/' + id,
            { params, headers: { 'Accept': 'application/octet-stream' }, observe: 'response', responseType: 'arraybuffer' }
        );
    }

    getFileDownload(path, forceTeam: uuid = null, accepts = 'application/octet-stream'): Observable<HttpResponse<Blob>> {
        let params: Params = {};
        if (forceTeam) {
            params = { _forceTeam: forceTeam }
        }
        return this.http.get(path,
            { params, headers: { 'Accept': accepts }, observe: 'response', responseType: 'blob' }
        );
    }

    doResponseDownload(fileResponse: HttpResponse<Blob>) {
        console.log(fileResponse);
        const contentType = fileResponse.headers.get('our-mime-type');
        const blob = new Blob([fileResponse.body], { type: contentType });
        const fileName = fileResponse.headers.get('our-file-name');
        const file = new File([blob], fileName, { type: contentType });
        console.log({ contentType, fileName, file });

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";

        const fileURL = URL.createObjectURL(file);

        a.href = fileURL;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(fileURL);
    }

    downloadFile(path: string, forceTeam: uuid = null, accepts = 'application/octet-stream') {
        this.getFileDownload(path, forceTeam, accepts).subscribe(
            (success) => this.doResponseDownload(success),
            badResponse => {
                this.handleOneError('Downloading File', null)(badResponse);
        });
    }

/*
    downloadFile(path: string, forceTeam: uuid = null, accepts = 'application/octet-stream') {
        this.getFileDownload(path, forceTeam, accepts).subscribe( fileResponse => {
            const contentType = fileResponse.headers.get('our-mime-type');
            const blob = new Blob([fileResponse.body], { type: contentType });
            const fileName = fileResponse.headers.get('our-file-name');
            const file = new File([blob], fileName, { type: contentType });
            console.log({ contentType, fileName, file });

            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";

            const fileURL = URL.createObjectURL(file);

            a.href = fileURL;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(fileURL);
        }, badResponse => {
            this.handleOneError('Downloading File',null)(badResponse);
        });
    }
*/
}
