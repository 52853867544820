<!-- app-form-page [config]="config"></app-form-page -->
<div style="margin:20px" formPageHolder>
    <h1>Agent Settings</h1>
    <div style="display:flex; flex-flow: row wrap;">
        <div style="width:330px;">
            <mat-card style="padding:20px; margin:10px">
                <app-text-edit label="Name" [textValue]="team.name" (editor)="editName()"></app-text-edit>
                <app-text-edit label="Legal Name" [textValue]="team.legalName" (editor)="editLegalName()"></app-text-edit>
                <app-text-edit label="Business Address" [textValue]="team.defaultAddress" (editor)="editAddress()" [isHtml]="true"></app-text-edit>
            </mat-card>
        </div>
        <div style="width:330px;">
            <mat-card style="padding:20px; margin:10px">
                <h4>Parameters</h4>
                <app-text-edit label="Billing Address" [textValue]="team.billingAddress" (editor)="editBillingAddress()"
                    (deleter)="deleteParameter('billingAddress', team.billingAddress)" [isHtml]="true"
                    deleteTip="Remove billing address and restore default value">
                </app-text-edit>
                <app-text-edit label="Payment Terms" [textValue]="team.paymentTerms" (editor)="editPaymentTerms()"
                    (deleter)="deleteParameter('paymentTerms', team.paymentTerms)"
                    deleteTip="Remove payment terms and restore default value">
                </app-text-edit>

                <app-text-edit label="Sending Email" [textValue]="team.fromEmail"></app-text-edit>
                <app-text-edit label="Reply To Address" [textValue]="team.replyToMail"></app-text-edit>

                <app-text-edit label="Mail Sending Name" [textValue]="team.mailSendingName" (editor)="editMailSendingName()"
                    (deleter)="deleteParameter('mailSendingName', team.mailSendingName)" [highlight]="getParameter('mailSendingName')"
                    deleteTip="Remove mail sending name and restore default value">
                </app-text-edit>
                <app-text-edit label="Mail Sending Account" [textValue]="team.mailSendingAccount" (editor)="editMailSendingAccount()"
                    (deleter)="deleteParameter('mailSendingAccount', team.mailSendingAccount)"
                    [highlight]="getParameter('mailSendingAccount')" deleteTip="Remove mail sending account and restore default value">
                </app-text-edit>

                <app-text-edit label="Mail Sending Domain" [textValue]="team.mailSendingDomain" (editor)="editMailSendingDomain()"
                    (deleter)="deleteParameter('mailSendingDomain', team.mailSendingDomain)"
                    [highlight]="getParameter('mailSendingDomain')"
                    deleteTip="Remove mail sending domain and use default OurOMC sending domain">
                </app-text-edit>
                <app-text-edit label="Mail Reply To Domain" [textValue]="team.mailReplyToDomain" (editor)="editMailReplyToDomain()"
                    (deleter)="deleteParameter('mailReplyToDomain', team.mailReplyToDomain)"
                    [highlight]="getParameter('mailReplyToDomain')"
                    deleteTip="Remove mail replyTo domain and use default OurOMC receipt domain">
                </app-text-edit>
            </mat-card>
        </div>
    </div>
</div>
