/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { AgentSettings } from 'src/app/model/AgentSettings';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AgentSettingsService extends AbstractHttpService{
    /** If you are editting here, you likely need to make same change in OMCSettingsService */
    protected baseUrl = AbstractHttpService.ajaxPath + 'agentSettings';
    protected cache: AgentSettings[];
    protected typeString = 'AgentSettings';

    protected http = inject(HttpClient);
    protected messageService = inject(MessageService);

    deleteParameter(parmName: string, parmValue: string, existing: AgentSettings): Observable<AgentSettings> {
        const opts = this.getSimpleOptions(null);
        opts['body'] = { currentValue: parmValue };
        return this.http.delete<AgentSettings>(this.baseUrl + '/' + parmName, opts).pipe(
            tap((x) => {
                if (x) {
                    this.messageService.show('Parameter removed and reverted to default');
                }
            }),
            catchError(this.handleOneError('Removing Agent Setting', existing))
        )
    }
}
