/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { uuid } from "./abstract-object";
import { Team } from "./team";

export class AgentSettings extends Team {
    mailSendingDomain: string;
    mailReplyToDomain: string;
    paymentTerms: string;
    fromEmail: string;
    replyToMail: string;
    billingAddress: string;
    mailSendingAccount: string;
    mailSendingName: string;
    parameters: { id: uuid, name: string, value: string }[] = [];
}
