/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { inject, Injectable } from '@angular/core';

import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';

import { User } from 'src/app/model/user';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { CurrentUserService } from './current-user.service';

@Injectable({
    providedIn: 'root'
})

export class UserService extends AbstractHttpService {
    http = inject (HttpClient);
    messageService = inject(MessageService);
    currentUserSvc = inject(CurrentUserService);

    protected baseUrl = this.ajaxPath + 'users';
    protected cache: User[] = [];

    protected agentUserCache: User[] = [];

    protected typeString = 'User';
    private teamUserSubject = new ReplaySubject<User[]>();

    private subjects: { [id: uuid]: Subject<User> } = {};

    constructor() {
        super();
        this.getTeamUsers();
    }

    private getCachedUser(id: uuid): User {
        return this.cache.find(user => user.id === id);
    }

    get<T extends AbstractObject>(useCache = false, params: HttpParams = null, forceTeamId: uuid = null): Observable<T[]> {
        console.warn("Use Get Users instead of this....", {useCache, params, forceTeamId});
        return this.teamUserSubject as unknown as Observable<T[]>;
    }

    getUser(id: uuid): Observable<User> {

        /* do I already have the user */
        const theUser = this.getCachedUser(id);
        if (theUser) { // I already have the user!
            return of(theUser);
        } else if (this.subjects[id]) { // I am already waiting for the user
            return this.subjects[id];
        } else if (this.hasData) { // Already have team users, this one must be outside of the team
            return this.requestAUser(id);
        } else { // Wait and see if it is in the team Users...
            this.subjects[id] = new Subject<User>();
            return this.subjects[id];
        }
    }

    public getUsers() {
        return this.teamUserSubject;
    }

    public getAgentUsers() : Observable<User[]> {
        if (this.agentUserCache.length > 0) {
            return of (this.agentUserCache);
        }
        return this.http.get<User[]>(`${this.ajaxPath}agentUsers`).pipe( map( au => {
            this.agentUserCache = au;
            return au;
        }), catchError(err => {
            this.messageService.showError(err, 'Retrieving agent users');
            return of([]);
        }));
    }

    /*
    * Will need to limit this to one site!
    */
    private getTeamUsers() {
        this.currentUserSvc.getCurrentUser().subscribe( u => {
            if (u?.current_team_id) {
                this.http.get<User[]>(`${this.baseUrl}/team/${u.current_team_id}`).subscribe((data: User[]) => {
                    this.teamUserSubject.next(data);
                    data.forEach ( u => this.cache.push(u));
                    this.hasData = true;
                    data.forEach( d => {
                        this.completeRequest(d);
                    });
                    for (const id in this.subjects) {
                        console.warn('Requesting ' + id)
                        this.requestAUser(id).subscribe ( u => this.completeRequest(u));
                    }
                });
            }
        });
    }

    completeRequest(u: User) {
        if(this.subjects[u.id]) {
            this.subjects[u.id].next(u);
            this.subjects[u.id].complete();
            delete this.subjects[u.id];
        }
    }

    private requestAUser(id: uuid): Observable<User> {
        return this.http.get<User>(this.baseUrl + '/' + id)
            .pipe(
                map((data: User) => {
                    this.cache.push(data);
                    return data;
                }),
                tap(() => console.log('fetched user ' + id)),
                catchError(this.handleError<User>('getUserId' + id, new User()))
            );
    }
}
