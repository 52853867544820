<!-- app-form-page [config]="config"></app-form-page -->
<div style="margin:20px" formPageHolder>
    <h1>OMC Settings</h1>
    <div style="columns: 330px">
        <div class="parmCard">
            <mat-card class="parmCard">
                <app-text-edit label="Name" [textValue]="team.name" (editor)="editName()"></app-text-edit>
                <app-text-edit label="Legal Name" [textValue]="team.legalName" (editor)="editLegalName()"></app-text-edit>
                <app-text-edit label="Default Address" [textValue]="team.defaultAddress" (editor)="editAddress()" [isHtml]="true"></app-text-edit>
                <app-text-edit label="Primary Agent" [textValue]="team.primaryAgentName"></app-text-edit>
                <app-text-edit label="Defaut Bank Account" [textValue]="team.bank_account?.name"></app-text-edit>

            </mat-card>
        </div>
        <div class="parmCard">
            <mat-card class="parmCard">
                <h4>Mail Parameters</h4>
                <app-text-edit label="Sending Email" [textValue]="team.fromEmail"></app-text-edit>
                <app-text-edit label="Reply To Address" [textValue]="team.replyToMail"></app-text-edit>

                <app-text-edit label="Mail Sending Name" [textValue]="team.mailSendingName" (editor)="editMailSendingName()"
                    (deleter)="deleteParameter('mailSendingName', team.mailSendingName)"
                    [highlight]="getParameter('mailSendingName')"
                    deleteTip="Remove mail sending name and restore default value">
                </app-text-edit>
                <app-text-edit label="Mail Sending Account" [textValue]="team.mailSendingAccount" (editor)="editMailSendingAccount()"
                    (deleter)="deleteParameter('mailSendingAccount', team.mailSendingAccount)"
                    [highlight]="getParameter('mailSendingAccount')"
                    deleteTip="Remove mail sending account and restore default value">
                </app-text-edit>
                <app-text-edit label="Mail Sending Domain" [textValue]="team.mailSendingDomain" (editor)="editMailSendingDomain()"
                    (deleter)="deleteParameter('mailSendingDomain', team.mailSendingDomain)"
                    [highlight]="getParameter('mailSendingDomain')"
                    deleteTip="Remove mail sending domain and use default OurOMC sending domain">
                </app-text-edit>
                <app-text-edit label="Mail Reply To Domain" [textValue]="team.mailReplyToDomain"
                    (editor)="editMailReplyToDomain()" (deleter)="deleteParameter('mailReplyToDomain', team.mailReplyToDomain)"
                    [highlight]="getParameter('mailReplyToDomain')"
                    deleteTip="Remove mail replyTo domain and use default OurOMC receipt domain">
                </app-text-edit>
                <button mat-button color="primary" (click)="sendTestMail()">Send Test Mail</button>
            </mat-card>
        </div>
        <div class="parmCard">
            <mat-card class="parmCard">
                <h4>Agent Permissions</h4>
                @for (a of team.agents; track $index) {
                    <app-text-edit label="{{a.agentName}} - {{a.agentRoleName}}" [textValue]="a.omcRoleName"></app-text-edit>
                }
            </mat-card>
        </div>

        <div class="parmCard">
            <mat-card class="parmCard">
                <h4>Billing Parameters</h4>
                <app-text-edit label="Payment Terms" [textValue]="team.paymentTerms"
                    (editor)="editPaymentTerms()" (deleter)="deleteParameter('paymentTerms', team.paymentTerms)"
                    [highlight]="getParameter('paymentTerms')"
                    deleteTip="Remove payment terms and restore default value">
                </app-text-edit>
                <app-text-edit label="Billing Address" [textValue]="team.billingAddress" [isHtml]="true"
                    (editor)="editBillingAddress()"  (deleter)="deleteParameter('billingAddress', team.billingAddress)"
                    [highlight]="getParameter('billingAddress')"
                    deleteTip="Remove billing address and restore default value">
                </app-text-edit>
            </mat-card>
        </div>

        <div class="parmCard">
            <mat-card class="parmCard">
                <h4>Default Budget Codes</h4>
                @for (dbc of team.defaultBCodes; track $index) {
                    <app-text-edit [label]="dbc.type" textValue="{{dbc.bCodeName}} ({{dbc.bCodeSort}})"></app-text-edit>
                }
            </mat-card>
        </div>
    </div>
</div>
