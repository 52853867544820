/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { Field } from 'src/app/shared/field/Field';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { AbstractHttpService } from '../../abstract-http.service';
import { TableCellComponent } from '../../table/table-cell/table-cell.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormComponent } from '../../form/form.component';
import { NgTemplateOutlet } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { DialogBannerComponent } from '../dialog-banner-component';
export interface DialogOptions {
    config: FormConfig;
    service: AbstractHttpService;
    id?: uuid;
    forceTeamId?: uuid;
    width?: number;
    height?: number;
    showSearch?: boolean;
    addOnly?: boolean;
    hideTabs?: boolean,
    condensed?:boolean
    // listParms?: HttpParams, Bit confusing showing table in popup... needs work, enabled in formpageComponent.setup comments
}
@Component({
    selector: 'app-pick-dialog',
    templateUrl: './pick-dialog.component.html',
    styleUrls: ['./pick-dialog.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatDialogContent, MatTabsModule, NgTemplateOutlet,
        FormComponent, MatFormFieldModule, MatInputModule, MatTableModule, MatTooltipModule,
        TableCellComponent, DialogBannerComponent]
})
export class PickDialogComponent implements OnInit {

    /*
    config: FormConfig = {
      fields:[
        Field.make.id(),
        Field.make.rev(),
        FormTextComponent.make('Title', 'title'),
        FormTextComponent.make('First Name', 'firstName'),
        FormTextComponent.make('Last Name', 'lastName'),
        FormTextComponent.make('Telephone', 'phone'),
        FormTextComponent.make('Email', 'email'),
        Field.make.notes(),
      ],
      title: $localize `People`,
      service: this.dataSvc,
      mode: 'new',
      objectFactory: () => new Person(),
      formLayout: FormComponent.formLayouts.singleCol
    };
  */
    config: FormConfig;
    columns: Field[] = [];
    columnNames: string[] = [];
    searchField = '';

    dataSource: MatTableDataSource<AbstractObject>;
    dataItems: AbstractObject[];
    height = 300;
    width = 500;
    showSearch = true;
    addOnly = false;

    listLimited = false;
    showList = true;

    constructor(protected isNarrowSvc: IsNarrowService,
        public dialogRef: MatDialogRef<PickDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {
        this.config = dialogOptions.config;
        this.config.service = dialogOptions.service;
        this.showSearch = dialogOptions.showSearch;
        if (dialogOptions.width) {
            this.width = this.isNarrowSvc.screenWidth * dialogOptions.width / 100
        } else {
            this.width = this.isNarrowSvc.screenWidth * .80;
        }
        if (dialogOptions.height) {
            this.height = this.isNarrowSvc.screenHeight * dialogOptions.height / 100;
        } else {
            this.height = this.isNarrowSvc.screenHeight * .75;
        }
        if (dialogOptions.addOnly) {
            this.addOnly = dialogOptions.addOnly;
        } else {
            dialogOptions.service.get(true, null, dialogOptions.forceTeamId).pipe(first()).subscribe(dataSet => {
                this.dataItems = dataSet;
                if (dataSet.length > 100) {
                    this.listLimited = true;
                    this.showList = false;
                }

                this.dataSource = new MatTableDataSource(dataSet);
                this.dataSource.filter = this.searchField;

            });
        }
        this.dialogRef.disableClose = true;
    }

    filter(search: string) {
        if (this.listLimited) {
            if (search.length > 2) {
                this.showList = true;
                this.dataSource.filter = search.trim().toLocaleLowerCase();
            } else {
                this.showList = false;
            }
        } else {
            this.dataSource.filter = search.trim().toLocaleLowerCase();
            this.dataSource.filteredData.length
        }
    }

    ngOnInit(): void {

        this.columns = [];
        this.columnNames = [];

        this.config.fieldSet.fields.forEach(field => {
            this.columns.push(field);
            if (field.visible.computer || field.visible.phone) {
                this.columnNames.push(field.name);
            }
        });
    }

    selectItem(item: AbstractObject) {
        this.dialogRef.close(item);
    }

    closeDialog() {
        this.dialogRef.close(null);
    }
}
