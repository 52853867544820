/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { Billing, BillingItem } from 'src/app/model/billing';
import { Cycle } from 'src/app/model/cycle';
import { Field } from 'src/app/shared/field/Field';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { required } from 'src/app/shared/validators';
import { BillingService } from '../billing.service';
import { CycleService } from '../cycle.service';
import { ScheduleService } from '../schedule.service';
import { MyUnitService } from 'src/app/modules/unit/my-unit.service';
import { Unit } from 'src/app/model/unit';
import { Schedule } from 'src/app/model/schedule';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { BCodeService } from '../bcode.service';
import { BCode } from 'src/app/model/bcode';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { InvoicePageComponent } from '../../txn/invoice-page/invoice-page.component';
import { formatCurrency } from '@angular/common';
import { InvoicingAction } from './invoicing-action/invoicing-action';
import { AddUnitAction } from './show-unit-billing/show-unit-billing.component';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { BillingDetailComponent } from './billing-detail/billing-detail.component';

@Component({
    selector: 'app-billing-page',
    templateUrl: './billing-page.component.html',
    styleUrls: ['./billing-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class BillingPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('budgets/billing', BillingPageComponent, 'receipt_long', BillingDetailComponent);

    units: Unit[] = [];
    schedules: Schedule[] = [];
    bcodes: BCode[] = [];

    attachmentGrid = AttachmentGridComponent.make(this.billingSvc, {readonly: true});

    invoiceLink = FormButtonComponent.makeNavButton('Billed', InvoicePageComponent.navRoute, o => ({ billingId: o.id }),
        { formColumn: 2, value: 'billedTotal', calculateValue: (o) => {
            if (o['totalItems'] > 0) {
                return `${formatCurrency(o['billedTotal'], 'EN-ie', '€')} (${o['totalItems']})`
            }
            return '';
        }
    });

    outstandingLink = FormButtonComponent.makeNavButton('Outstanding', InvoicePageComponent.navRoute,
        o => ({ billingId: o.id, outstanding: '>=0.01' }),
        { formColumn: 2, value: 'outstandingTotal',
            calculateValue: (o) => {
                if (o['totalItems'] > 0) {
                    return `${formatCurrency(o['outstandingTotal'], 'EN-ie', '€')} (${o['outstandingItems']})`
                }
                return '';
            }
        }
    );

    /** Filthy but directly accessed by ShowUnitBillingComponent... */
    scheduleGrid = new GridField({
        field: { value: 'items', cellOpts: { heading: 'Schedules' }, visible: Field.formOnly, formColumn: 3 },
        rowFactory: this.billingItemFactory.bind(this),
        objFactory: () => of(new BillingItem()),
        hasFooter: true
    });

    mailMergeGrid = new GridField({
        field: { value: 'mailMerges', label: 'Mail merges', readonly: true, visible: Field.formOnly, formColumn: 3},
        rowFactory: this.mailMergeFactory.bind(this)
    })

    fieldSet = new FieldSet({
        fields: [
            FieldMaker.id(),
            FieldMaker.rev(),
            FieldMaker.nameControl({ validators: [required] }),
            FormPicklistComponent.make('Cycle', 'cycleId', 'cycle', { service: this.cycleSvc }, { disable: true }),
            FormPicklistComponent.make('Status', 'statusId', '', { items: Cycle.statuses }, { disable: true }),
            this.invoiceLink,
            this.outstandingLink,
            FieldMaker.notes(),
            this.scheduleGrid,
            this.mailMergeGrid,
        ],
        formLayout: [{ cells: [{ width: '30%' }, { width: '20%' }, { width: '40%' }, { width: '10%' }] }],
    })

    config = new FormConfig({
        navRoute: BillingPageComponent.navRoute,
        title: $localize`Billing`,
        help: $localize`Billings take an amount of monies and raise service charges apportioned per unit for the total amount.`,
        readonly: true,
        fieldSet: this.fieldSet,
        service: this.billingSvc,
        mode: 'list',
        objectFactory: () => of(new Billing()),
        actions: [new AddUnitAction(this), new BilloutChargeAction(), new InvoicingAction()],
        beforeEdit: this.beforeEdit.bind(this),
        tabFields: [this.attachmentGrid],
        configReady: this.configReady,
        allowDelete: false,
    });

    constructor(private billingSvc: BillingService, private cycleSvc: CycleService, private bcodeSvc: BCodeService,
        private scheduleSvc: ScheduleService, private myUnitSvc: MyUnitService) {
        super();
        this.getUnitSetup();
    }

    getUnitSetup() {
        forkJoin({
            schedules: this.scheduleSvc.get(true),
            bcodes: this.bcodeSvc.get(true),
            units: this.myUnitSvc.get(),
        }).subscribe(result => {
            this.units = result.units as Unit[];
            this.schedules = (result.schedules as Schedule[]);
            this.bcodes = (result.bcodes as BCode[]);
            this.configReady.next(null);
        })
    }

    unitPercent(unit: Unit, bi: BillingItem) {
        const portion = unit.portions.find(p => p.scheduleId === bi.scheduleId);
        return portion ? portion.percent : 0;
    }

    mailMergeFactory() {
        const flds = [
            FieldMaker.id({ visible: Field.noShow }),
            FormTextComponent.make('Name', 'name'),
            FormNumberComponent.makeCurrency('Todo', 'todo'),
            FormNumberComponent.makeCurrency('Done', 'done'),
            FormTextComponent.make('Status', 'status'),
        ];
        return flds;
    }

    billingItemFactory(bi: BillingItem) {
        const flds = [
            FieldMaker.id({ visible: Field.noShow }),
            FormPicklistComponent.make('Schedule', 'scheduleId', 'schedule',
                { service: this.scheduleSvc },
                {
                    disable: true,
                    footer: { text: 'Total', style: 'font-weight:500; text-align: right' }
                }),
            FormPicklistComponent.make('Budget Code', 'bCodeId', null,
                { items: this.bcodes },
                {
                    disable: true,
                    footer: { text: 'Total', style: 'font-weight:500; text-align: right' }
                }),
            FormNumberComponent.makeCurrency('Total', 'total'),
            FormNumberComponent.makeCurrency('Billed', 'billed'),
        ];
        for (const u of this.units) {
            const toolTip = bi ? ` ${(Math.round(this.unitPercent(u, bi) * 100000) / 1000)}% of ${bi.total}` : '';
            flds.push(FormNumberComponent.makeCurrency('Unit ' + u.name, 'unit-' + u.id, { toolTip }));
        }
        return flds;
    }
    getKey(bi: BillingItem) {
        return (bi.scheduleId as string) + (bi.bCodeId as string);
    }

    beforeEdit(o: Billing) {
        o.units = this.units;
        for (const u of o.units) {
            let uTotal = 0;
            //for (const s of this.schedules) {
            for (const bi of o.items) {
                const uPortion = this.getAmount(o, u, bi);

                u[this.getKey(bi)] = uPortion;
                uTotal += uPortion;

                //o['sched' + s.id + '-' + u.id] = uPortion;
            }
            for (const bi of o.items) {
                bi['unit-' + u.id] = this.getAmount(o, u, bi)
            }
            u['total'] = uTotal;

            o['total-' + u.id] = uTotal;
        }

        return o;
    }

    getAmount(billing: Billing, unit: Unit, charge: BillingItem) {
        //const charge = billing.items.find(s => s.scheduleId === schedule.id);
        if (charge) {
            return charge.total * this.unitPercent(unit, charge);
        } else {
            console.error('No charge for schedule', { billing, unit, charge });
            return 0;
        }
    }
}

class BilloutChargeAction implements IFormAction {
    name = $localize`Billout`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'euro_symbol';
    approvalNeeded = true;
    approvalText = $localize`Issue service charges - WARNING THIS CANNOT BE UNDONE?`;
    billing: Billing;

    action(billing: Billing, config: FormConfig) {
        return (config.service as BillingService).billout(this.billing);
    }
    setup(billing: Billing) {
        this.billing = billing;
        this.show = billing.statusId === Cycle.statusIds.ACCEPTED;
    }
}
