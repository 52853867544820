/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AbstractObject, uuid } from "../model/abstract-object";
import { AbstractHttpService } from "./abstract-http.service";
import { MyInjector } from "../app.module";
import { catchError, map } from "rxjs/operators";
import { MessageService } from "./message.service";
import { of } from "rxjs";
import { MailItem } from "../model/mailMerge";

export class MailHelper {
    http = MyInjector.instance.get(HttpClient);
    msgSvc = MyInjector.instance.get(MessageService);

    constructor(private baseService: AbstractHttpService) {
    }

    getMail(item: AbstractObject, mailItemId: uuid) {
        const url = this.baseService.getBaseUrl() + '/getMail/' + item.id + '/' + mailItemId;
        const params = new HttpParams().set('_forceTeam', item.teamId)
        const headers = new HttpHeaders().append('Accept', 'application/text');

        return this.http.get(url, { params, headers, observe: 'response', responseType: 'text' }).pipe(
            map(x => {
                return x;
            }),
            catchError((error) => {
                this.msgSvc.showError(error, 'Retrieving Mail');
                return of(null);
            })
        )
    }

    showMail(item: AbstractObject, mi: MailItem) {
        this.getMail(item, mi.id).subscribe(htmlResponse => {
            if (htmlResponse) {
                const width = Math.max((screen.width / 3) * 2, 400);
                const height = Math.max((screen.height / 5) * 3, 800);
                const top = (screen.height - height) / 2;
                const left = (screen.width - width) / 2;
                const title = "Message Sent To " + mi.toAddress + " : " + mi.subject;
                const baseOptions = "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes";
                const options = `${baseOptions},width=${width},top=${top},left=${left},height=${height}`
                const win = window.open("", title, options);
                const when = new Date(mi.createdAt * 1000).toLocaleDateString('en-IE', { weekday: "long", month: "long", day: "numeric" });
                const time = new Date(mi.createdAt * 1000).toLocaleTimeString('en-IE')
                const from = mi.fromAddress;
                const to = mi.toAddress;
                console.log(from);
                const document = `<html>
            <div style="margin-left: 20px; margin-right: 20px; line-height: 1.2em">
            <hr/>
            <p style="margin-bottom: 0px"><b>From</b>: ${from}</p>
            <p style="margin-bottom: 0px"><b>To</b>: ${to}</p>
            <p style="margin-bottom: 0px"><b>Sent</b>: ${when} @ ${time}</p>
            <p style="margin-bottom: 0px"><b>Subject</b>: ${mi.subject}</p>
            <hr/>
            ${htmlResponse.body}
            </div>
            </html>`
                win.document.title = title;
                win.document.body.innerHTML = document;
            }
        }
        );
    }
}