/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { Observable } from 'rxjs';
import { OmcSettings } from 'src/app/model/OmcSettings';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { MessageService } from 'src/app/shared/message.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OmcSettingsService extends AbstractHttpService{
    /** If you are editting here, you likely need to make same change in AgentSettingsComponent */
    protected baseUrl = AbstractHttpService.ajaxPath + 'omcSettings';
    protected cache: OmcSettings[];
    protected typeString = 'OMCSettings';

    protected http = inject(HttpClient);
    protected messageService = inject(MessageService);

    getAgencies() : Observable<Agency[]> {
        return this.http.get<Agency[]>(this.baseUrl + '/agencies')
    }

    sendTestMail(omcSettings: OmcSettings) {
        this.http.post(this.baseUrl + '/sendTestMail', {}, this.getSimpleOptions(omcSettings.id)).subscribe(
            result => {
                console.log(result);
                this.messageService.show('Test mail sent, please check your mailbox');
            },
            error => {
                this.messageService.showError(error, 'Sending Test Mail');
            }
        )
    }

    deleteParameter(parmName: string, parmValue: string, existing: OmcSettings) : Observable<OmcSettings> {
        const opts = this.getSimpleOptions(null);
        opts['body'] = { currentValue: parmValue};
        return this.http.delete<OmcSettings>(this.baseUrl+ '/' + parmName, opts ).pipe(
            tap( (x) => {
                if (x) {
                    this.messageService.show('Parameter removed and reverted to default');
                }
            }),
            catchError(this.handleOneError('Removing OMC Setting', existing))
        )
    }
}

export class Agency extends AbstractObject {
    agentTeamId: uuid;
    agentRoleId: uuid;
    omcRoleId: uuid;
}
