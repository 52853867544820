<app-dialog-banner text="{{dialogOptions.title}} Picklist" [dialogRef]="dialogRef"></app-dialog-banner>
<mat-dialog-content style="padding: 10px; width: calc(min(80vw - 40px, 530px))">
<p>Hello World</p>
    <app-field-set [fieldSet]="dialogOptions.fieldSet" [initComplete]="true"></app-field-set>

    <div style="display:flex; margin-top: 10px">
        <button mat-raised-button color="primary" (click)="save()">{{saveText}}</button>
        <div style="flex: 1"></div>
        <button mat-raised-button color="accent" [mat-dialog-close]="null">Cancel</button>
    </div>

</mat-dialog-content>
