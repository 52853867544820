/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { STD_ANIMATION } from 'src/app/shared/std-animation';
import { Field } from 'src/app/shared/field/Field';
import { Sort, MatSortModule } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TableSettingsComponent } from './table-settings/table-settings.component';
import { FormConfig } from "../form/FormConfig";
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { IsNarrowService } from '../is-narrow.service';
import { PicklistField } from '../field/PicklistField';
import { ConfirmDialogService } from '../dialogs/confirmDialog';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { TableCellComponent } from './table-cell/table-cell.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

class Page {
    id: number;
    start: number;
    end: number;
    show: boolean;
}

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    animations: [STD_ANIMATION],
    standalone: true,
    imports: [
        MatTableModule, MatButtonModule, MatIconModule, NgTemplateOutlet,
        MatSortModule,
        MatTooltipModule,
        TableCellComponent,
        NgClass,
    ],
})
export class TableComponent implements OnChanges {

    @Input() config: FormConfig;
    @Input() drilldown = true;
    @Input() dataSet: AbstractObject[];
    @Input() displayHeight: number;
    @Input() maxRecords = 10;
    pages: Page[] = [];
    currentPage: Page = {id: 1, start: 1, end: this.maxRecords, show: true};
    maxPages = 8;
    math = Math;

    @Output() sorter = new EventEmitter();
    /* @Output() editor = new EventEmitter<AbstractObject>(); */

    data: AbstractObject[] = [];
    columns: Field[] = [];
    columnNames: string[] = [];

    forceTeamId: uuid;

    focusItem: AbstractObject | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortedData: any[] = [];

    loadingData = true;

    tableHeight = 500;
    isPrint = false;
    isPhone = false;
    isPhoneLand = false;

    hideCharts = false;

    protected sorters = {}; // Custom Sort Objects

    private currentSort: Sort = { active: '', direction: 'asc' };

    constructor(private matDialog: MatDialog, private isNarrowSvc: IsNarrowService,
        private cds: ConfirmDialogService,
        protected router: Router, protected activeRoute: ActivatedRoute) {
        this.tableHeight = isNarrowSvc.screenHeight - 458; // 200 for charts...
        this.checkLandscape();
        this.isNarrowSvc.detectVeryNarrow().subscribe(
            result => {
                this.isPhone = result;
                this.getColumnNames();
            }
        );
        this.isNarrowSvc.resizeObservable$.subscribe( () => {
            this.checkLandscape()
        });
    }
    checkLandscape() {
        if (this.isNarrowSvc.screenHeight < 500) {
            this.isPhoneLand = true;
        }
    }

    toggleCharts() {
        if (this.hideCharts) {
            this.hideCharts = false;
            this.tableHeight -= 200;
        } else {
            this.hideCharts = true;
            this.tableHeight += 200;
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataSet && changes.dataSet.currentValue !== changes.dataSet.previousValue) {
            this.loadData(this.dataSet);
        }
        if (changes.displayHeight) {
            this.tableHeight = this.displayHeight;
        }
        if (changes.config) {
            this.config.isReady.subscribe(() => {
                this.configTable();
            });
        }
    }
    configTable() {
        this.columns = this.getColumns();
        this.getColumnNames();
        if (!this.config.charts) {
            this.tableHeight += 200;
        } else if (this.tableHeight <= 300) {
            this.tableHeight += 200;
            //this.hideCharts = true; No need to hide, the table can grow we are in landscape mode
        }
    }

    showAll($event: PointerEvent) {
        $event.preventDefault();
        $event.stopPropagation();
        let msg = $localize`Displaying ${this.data.length} records may be slow, do you really want to show them all?`;
        if (this.data.length > 250) {
            msg = $localize`Displaying ${this.data.length} records WILL BE SLOW, printing will be even slower, your system may become unusable. Are you really sure you want to display them all? You should consider simplifying the table to be displayed/printed by removing any unnecessary fields`;
        }
        if (this.data.length > 1000) {
            msg = $localize`Displaying ${this.data.length} records WILL TAKE A VERY LONG TIME, printing will be even slower, your system may become unusable. It will display a little quicker if you remove unnecessary fields from the table. Are you really sure you want to display them all?`;
        }
        this.cds.open($localize`Display All`, msg, () => {
            this.loadingData = true;
            window.setTimeout(() => {
                this.maxRecords = this.data.length;
                this.onSort(this.currentSort, this.data.length);
                this.loadingData = false;
            }, 100);
        })
    }

    addSorter(name: string, sorter: AbstractObject[], key = 'id', value = 'name') {
        this.sorters[name] = {};
        sorter.forEach(o => {
            this.sorters[name][o[key]] = o[value];
        });
    }


    onEdit(object: AbstractObject, $event) {
        if ($event.target.href) {
            // Clicking on a hyperlink let it follow it
        } else if (this.drilldown) {
            this.focusItem = object;
            // Clicking anywhere else on row, navigate to edit page!
            const drillDownPath = this.config.pathEdit(object);
            let parms = {};
            if (this.forceTeamId) {
                parms = { _forceTeam: this.forceTeamId };
            } else if (this.config.forceTeamSwitchOnDrilldown) {
                parms = { _forceTeam: object.teamId };
            }
            this.router.navigate([drillDownPath, parms]);
        }
    }

    getColumns() {
        const arr = [];
        this.config.fieldSet.fields.forEach(field => {
            if (field) {
                arr.push(field);
                if (field instanceof PicklistField) { // (field.type === 'picklist') {
                    if (field.picklist.service) {
                        field.picklist.service.get(true).pipe(first()).subscribe(items => {
                            this.addSorter(field.name, items);
                        });
                    } else if (field.picklist.items.length > 0) {
                        this.addSorter(field.name, field.picklist.items);
                    }
                }
            }
        });
        return arr;
    }

    getColumnNames() {
        const arr = ['__firstCol'];
        this.columns.forEach(tableCol => {
            if ((!this.isPhone && tableCol.visible.computer) || (this.isPhone && tableCol.visible.phone)) {
                arr.push(tableCol.name);
            }
        });
        arr.push('__lastCol');
        this.columnNames = arr;
    }

    tableSettings(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { object: this.constructor.name, columns: this.columns, refresh: this.getColumnNames.bind(this) };
        this.matDialog.open(TableSettingsComponent, dialogConfig);
    }

    loadData(data: AbstractObject[]) {
        this.data = data;
        this.getColumnNames();
        this.refreshTable();
    }

    clearData() {
        this.data = [];
        this.onSort();
    }

    refreshTable() {
        this.onSort();
        this.loadingData = false;
    }

    setPages(items: number, maxRecords: number) {
        const pageCnt = Math.floor(items / maxRecords);
        const maxPages = 5;
        this.pages.length = 0;
        for (let i = 0; i<= pageCnt; i++) {
            let show = true;
            if ( (i > (this.currentPage.id) + 1 && i > maxPages -1 ) 
            || (i < (this.currentPage.id -3) && (i < pageCnt - 5))) {
                show = false;
            }
            const start = (i * maxRecords + 1);
            const end = Math.min(start + maxRecords -1, items);
            if (start <= items) {
                this.pages.push({id: i+1, start, end, show});
            }
        }
        if (this.currentPage.id === 1 && this.pages.length > 0) {
            this.currentPage = this.pages[0];
        }
    }

    setMaxRecords($event) {
        const newMax = +$event.target?.value;
        if (newMax <= 50) {
            this.maxRecords = newMax;
            this.setPages(this.data.length, this.maxRecords)
            this.gotoPage(1);
        } else {
            this.showAll($event);
        }
    }

    gotoPage(pageId: number) {
        this.currentPage = this.pages.find( o => o.id === pageId);
        this.onSort();
    }   

    onSort(sort: Sort = this.currentSort, maxRecords = this.maxRecords) {
        this.currentSort = sort;
        const data = this.data.slice();
        
        if (!sort.active || Field.isEmpty(sort.direction)) {
            this.sortedData = data.slice(this.currentPage.start -1, this.currentPage.start + maxRecords -1);
        } else {
            const sortFieldName = sort.active.replace('.', '_');
            const sortField = this.columns.find(fld => fld.name === sortFieldName);
            if (!sortField) {
                console.warn('Sort Field Not Found', { tc: this.columns, all: this.config.fieldSet.fields, sort });
            }
            const sortedData = data.sort((a: AbstractObject, b: AbstractObject) => {
                const isAsc = sort.direction === 'asc';
                const customSorter = this.sorters[sort.active];
                const aValue = sortField.getValue(a);
                const bValue = sortField.getValue(b);

                if (customSorter) {
                    return compare(customSorter[a[sort.active]], customSorter[b[sort.active]], isAsc);
                } else {
                    return compare(aValue, bValue, isAsc);
                }
            });
            this.sortedData = sortedData.slice(this.currentPage.start -1, this.currentPage.start + maxRecords -1);
        }
        this.setPages(this.data.length, maxRecords);
    }
}

const compare = (a: number | string, b: number | string, isAscending: boolean) => {
    const emptyA = isEmpty(a);
    const emptyB = isEmpty(b);

    if (emptyA && emptyB) {
        return 0;
    } else if (emptyA) {
        return 1;
    } else if (emptyB) {
        return -1;
    } else if (a === b) {
        return 0;
    } else {
        if (+a && +b) {
            a = Number(a);
            b = Number(b);
        } else if (typeof a === 'string' && typeof b === 'string') {
            a = a.toUpperCase();
            b = b.toUpperCase();
        }
        return (a < b ? -1 : 1) * (isAscending ? 1 : -1);
    }
};

const isEmpty = (o) => {
    if (o === undefined || o === null || o === '') {
        return true;
    } else {
        return false;
    }
};
