/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/

import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { LeadProfileService } from '../lead-profile.service';
import { FormsModule } from '@angular/forms';
import { FieldSetComponent } from 'src/app/shared/form/field-set/field-set.component';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [ MatButtonModule, FormsModule, FieldSetComponent, RouterLink ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent {

    textCtl = FormTextAreaComponent.make('Your Message', 'message').makeControl();

    lps = inject(LeadProfileService);
    http = inject(HttpClient);
    msgSvc = inject(MessageService);
    router = inject(Router);

    isFreeTrial = false;

    profileFieldSet = this.lps.fieldSet;

    message = '';
    msgValid = true;

    constructor(ar: ActivatedRoute) {
        ar.params.subscribe( this.changeParms.bind(this) );
    }

    changeParms(parms: Params) {
        console.log(parms);
        let text = "Hello, \n\nWe would like to take advantage of your free trial offer. \n\n";
        if ('trial' in parms) {
            this.isFreeTrial = true;
            if (parms.isAgent === 'true') {
                text += `We are an agency with ${parms.users} users and we manage ${parms.units} units.\n\n`;
                if ('full' in parms) {
                    text += "We are also interested in your hosted services for Microsoft and Wordpress. \n\n"
                }
                text += "We would appreciate if you could contact us ASAP to setup the free trial"
                this.message = text;
            } else {
                text += `We are an OMC with ${parms.units} units`;
                if (parms.hasAgent !== 'true') {
                    text +=  ' who self manage';
                }
                text += ".\n\nWe would appreciate if you could contact us ASAP to setup the free trial"
                this.message = text;
            }
        }

        if ('wantSignup' in parms) {
            text = `Hello\n\nI would like to request access to ${parms.wantSignup}\n\n`;
            text += 'Can you please contact the agent on our behalf and ask them to except OurOMC requests';
            this.message = text;
        }

    }

    sendMessage() {
        if (!this.profileFieldSet.getFormGroup().valid) {
            this.profileFieldSet.formGroup.markAllAsTouched();
            this.profileFieldSet.formGroup.markAsDirty();
            this.profileFieldSet.fields.forEach( f => {
                if (!f.control?.valid) {
                    console.log(f.name + ' is invalid', f);
                }
            })
            this.msgSvc.show(new MsgDef('Please complete all fields', 'warn'));
        } else if (this.message.length < 10) {
            this.msgValid = false;
            this.msgSvc.show(new MsgDef('Please enter a longer message', 'warn'));
        } else {
            this.reallySendMessage();
        }
    }
    changeMsg() {
        if (!this.msgValid && this.message.length > 10) {
            this.msgValid = true;
        }
    }

    reallySendMessage() {
            const url = AbstractHttpService.ajaxPath + 'contactus';
            this.lps.saveUserData();
            const data = this.lps.profile;
            data['message'] = this.message;
            if (this.isFreeTrial) {
                data['trial'] = "true";
            }

            this.http.post(url, data).subscribe(() => {
                this.msgSvc.show($localize`Thank you for your enquiry we will be in contact as soon as possible`);
                this.router.navigate(['/']);
            }, failure => {
                this.msgSvc.showError(failure, ' contacting OurOMC - please try again later');
            })
    }


}
