<div style="display: flex">
    <p class="label">{{label}}</p>
    @if (editor.observers.length > 0) {
        <mat-icon color="primary" [matTooltip]="editTip" (click)="editor.emit()">edit</mat-icon>
    }

    @if (deleter.observers?.length > 0) {
        <mat-icon color="warn" [matTooltip]="deleteTip" (click)="deleter.emit()">delete</mat-icon>
    }
</div>
@if (isHtml) {
    <p [style.font-weight]="highlight ? 'bold' : null" [innerHTML]="textValueToShow"></p>
} @else {
    <p [style.font-weight]="highlight ? 'bold' : null">{{textValueToShow}}</p>
}


