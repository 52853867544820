<!--
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*
-->
@if ((config && config.mode === 'list') || !config) {
    <div tableHolder>
        <div class="ouromc-table-holder mat-elevation-z8" [style.max-height.px]="isPhone || isPrint || isPhoneLand ? null: tableHeight"
            style="overflow:auto;">
            <table mat-table matSort (matSortChange)="onSort($event)" [dataSource]="sortedData" class="ouromc-table"
            @StdAnimation>
            @for (field of columns; track field; let colIndex = $index) {
            <div>
                <ng-container [(matColumnDef)]="field.name">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="field.value" [style]="field.cellOpts.thStyle"
                    [attr.colspan]="field.cellOpts.thColspan" [width]="field.cellOpts.width">
                    {{field.cellOpts.heading}}
                </th>
                <td mat-cell *matCellDef="let focusItem" [matTooltip]="field.toolTip" [style]="field.cellOpts.style"
                    [width]="field.cellOpts.width" [attr.data-field]="field.name">
                    <app-table-cell [field]="field" [focusItem]="focusItem"></app-table-cell>
                </td>
                </ng-container>
            </div>
            }
            <!-- These columns automatically added to start and end of tables to keep the padding correct! -->
            <ng-container matColumnDef="__firstCol">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let focusItem"></td>
            </ng-container>
            <ng-container matColumnDef="__lastCol">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let focusItem"></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true" data-table-headers></tr>
            <tr mat-row *matRowDef="let row; columns: columnNames; let rowNr = index; let even = even"
                (click)="onEdit(row, $event)" [attr.data-table-row]="rowNr" [ngClass]="{'highlight': row === focusItem,
                    'boldened' : config.tableHighlighter ? config.tableHighlighter(row) : false,
                    'even' : even,
                    'odd' : !even
                    }">
            </tr>
            </table>
        </div>
    </div>
    <ng-container [ngTemplateOutlet]="paginator"></ng-container>
}
<ng-template #paginator>
    <div class="paginator">
        @if (!loadingData) {
            @if (data.length === sortedData.length) {
                <div style="flex: 1"></div>
                <div style="text-align: right">
                    Showing <ng-container [ngTemplateOutlet]="itemsPerPage"></ng-container> items
                </div>
            }
            @if (data.length !== sortedData.length) {   
                <div style="flex:1"></div>
                <div class="pageBtn">
                    <button mat-button color="primary" class="pageBtn" [disabled]="currentPage.id === 1" matTooltip="First Page: Items 1 - {{maxRecords}} items" (click)="gotoPage(1)"><mat-icon>fast_rewind</mat-icon></button>
                    <button mat-button color="primary" class="pageBtn" [disabled]="currentPage.id === 1" matTooltip="Previous {{maxRecords}} items" (click)="gotoPage(currentPage.id - 1)"><mat-icon>arrow_back_2</mat-icon></button>
                    @for (p of pages; track $index) {
                        @if (p.id === currentPage.id) {
                            <button mat-button disabled class="pageBtn" style="font-weight:bold">{{p.id}}</button>
                        } @else if (p.show) {
                            <button mat-button color="primary" class="pageBtn" style="max-width: 2em;" (click)="gotoPage(p.id)" matTooltip="Show {{p.start}} to {{(p.end)}}">{{p.id}}</button>
                        }
                    }
                    <button mat-button color="primary" class="pageBtn" [disabled]="(currentPage.id === pages.length)"  matTooltip="Next {{maxRecords}} items" (click)="gotoPage(currentPage.id + 1)"><mat-icon>play_arrow</mat-icon></button>
                    <button mat-button color="primary" class="pageBtn" [disabled]="(currentPage.id === pages.length)"  matTooltip="Last Page ({{pages.length}}): items {{data.length - maxRecords}} to {{data.length}}" (click)="gotoPage(pages.length)"><mat-icon>fast_forward</mat-icon></button>
                </div>
                <div style="flex: 1; text-align:right; padding-bottom: 3px">
                    <ng-container [ngTemplateOutlet]="itemsPerPage"></ng-container>
                    <span> per page, showing items {{currentPage.start}} - {{currentPage.end}} of {{data.length}}</span>
                </div>
                <!-- span>
                    Displaying first {{sortedData.length}}
                    of <span data-table-record-count>{{data.length}}</span> records.
                    Click <a href="./showAll" (click)="showAll($event)">here</a> to show all
                </span -->            
            }
        }
        @if (loadingData) {
            <span>Loading Data, please wait...</span>
        }
    </div>
</ng-template>

<ng-template #itemsPerPage>
    <select (change)="setMaxRecords($event)" style="border:none">
        <option value="10" [selected]="maxRecords===10">10</option>
        @if (data.length > 20) {
            <option value="20" [selected]="maxRecords===20">20</option>
        }
        @if (data.length >= 50) {
            <option value="50" [selected]="maxRecords===50">50</option>
        }
        @if (data.length > 50) {
            <option [value]="data.length" [selected]="maxRecords===data.length">{{data.length}}</option>
        }
    </select>
</ng-template>
