/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { first  } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { Router } from '@angular/router';
import { Team } from 'src/app/model/team';
import { Omc } from 'src/app/model/Omc';
import { Role } from 'src/app/model/role';
import { NavRoute } from 'src/app/shared/NavRoute';
import { NavItem } from 'src/app/model/sys/nav-item';
import { TermsOfServiceComponent } from 'src/app/shared/terms-of-service/terms-of-service.component';
import { User } from 'src/app/model/user';
import { PersonUnitRole } from 'src/app/model/person-unit-role';
import { Forum, ForumPrivName } from 'src/app/model/forum';
import { DefaultBCode, DefaultBCodeType } from 'src/app/model/bcode';
import { uuid } from 'src/app/model/abstract-object';
import { ConfirmDialogComponent, ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { OurAppTrackerService } from 'src/app/shared/our-app-tracker-service';
import { MatDialogRef } from '@angular/material/dialog';
import { buildVersion } from 'src/environments/version';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService extends AbstractHttpService {

    currentUser: User; // = new User();
    currentTeam: Team;
    currentRole: Role;

    gotUser = false;
    gettingUser = false;

    broadcastChannel = new BroadcastChannel('userLogins');
    thisInstance = Math.random();
    alertOpen: MatDialogRef<ConfirmDialogComponent>

    static readonly returnMe = "returnMeTo";

    protected cache;
    protected typeString = 'CurrentUser';
    // wpOnly protected baseUrl = this.ajaxPath + 'wp/v2/users/me';
    protected baseUrl = this.ajaxPath + 'user';

    //private subjectUser = new BehaviorSubject<User | null>(null);
    private subjectUser = new ReplaySubject<User | null>(1);
    private subjectDefaultBCodes = new ReplaySubject<DefaultBCode[] | null>;

    constructor(protected http: HttpClient,
        protected messageService: MessageService, private cds: ConfirmDialogService, private ourAppTracker: OurAppTrackerService,
        protected router: Router) {
        super();
        this.broadcastChannel.onmessage = (ev: MessageEvent) => {
            console.log({ev, user: this.currentUser});
            if (this.alertOpen) {
                this.alertOpen.close();
            }
            const cu = this.currentUser;
            if (ev.data.from !== this.thisInstance
                && (ev.data.userId !== cu?.id || ev.data.teamTo !== this.currentTeam?.id || ev.data.roleId !== cu.currentRole?.id)) {
                const toWhere = ev.data.omcName ? ev.data.omcName : ev.data.teamTo
                const msg = (this.currentUser && ev.data.userId) ? $localize`Login changed, switched role or team to [${ev.data.roleName}] in [${toWhere}] by another tab/window,
                     this tab/window needs to be reloaded, press OK to reload this page in new context
                     or use other tab/window to switch back to [${this.currentUser.currentRole.name}] in [${this.currentTeam.name}]
                ` : (ev.data.userId ? `User has logged in using another window or tab` : `User has been logged out`);
                this.alertOpen = this.cds.alert(
                    $localize`Changed context user, role or team`,
                    msg,
                    () => ourAppTracker.reloadPage())
            }
        }
    }

    private checkLoaded() {
        if (!this.currentUser || !this.gotUser || this.gettingUser) {
            console.error('Getting forums before user retrieved');
        }
    }

    getForums(withPriv: ForumPrivName = null) {
        this.checkLoaded()
        return this.currentUser.forumRoles.reduce((unique, current) => {
            if (unique.find(o => o.id === current.forumId && o.teamId === current.teamId)) {
                //already have it;
            } else {
                if (current.teamId === this.currentTeam.id && (withPriv === null || current[withPriv])) {
                    //console.log({ forum: current.forumName, withPriv, current});
                    unique.push({ id: current.forumId, name: current.forumName, teamId: current.teamId } as Forum);
                }
            }
            return unique;
        }, [] as Forum[]);
    }

    getActivePositions() {
        this.checkLoaded()
        return this.currentUser.activePositions.reduce((unique, current) => {
            if (unique.find(o => o.roleId === current.roleId && o.teamId === current.teamId)) {
                //already fo it;
            } else {
                unique.push(current);
            }
            return unique;
        }, [] as PersonUnitRole[]);
    }

    private initNavItem(ni: NavItem) {
        if (ni.model && ni.model !== null) {
            const nr = NavRoute.getRoute(ni.url);
            if (nr && this.currentUser && this.currentUser.currentTeam) {
                ni.views = nr.getViews(this.currentUser);
                ni.charts = nr.getCharts();
            }
        }

        if (ni.children) {
            for (const nic of ni.children.filter(nicf => nicf.model)) {
                this.initNavItem(nic);
            }
        }
    }

    pathToModel(modelToPath: { [key: string]: string }) {
        const pathToModel = {};
        for (const [key, value] of Object.entries(modelToPath)) {
            pathToModel[value] = key;
        }
        return pathToModel;
    }

    httpGetCurrentUser(goto: string = null) {
        this.gettingUser = true;
        this.gotUser = false;

        this.http.get<User>(AbstractHttpService.ajaxPath + 'currentUser', this.httpOptions).pipe(first()).subscribe(currentUser => {

            TermsOfServiceComponent.checkTermsOfService(currentUser);
            if (currentUser && typeof currentUser.currentVersion === 'string') {
                if (currentUser.currentVersion.trim() !== buildVersion) {
                    const newUrl = window.origin + '/;rnd=' + Math.random();
                    console.log({ server: currentUser.currentVersion.trim(), client: buildVersion, newUrl });
                    this.cds.alert('Version Update', 'There has been a new software version released, we will need to refresh your browser', () => {
                        window.location.href = newUrl;
                    });
                }
            }

            this.currentUser = currentUser;
            this.currentTeam = currentUser.currentTeam;

            if (!this.currentUser.navItems) {
                this.currentUser.navItems = [];
            }
            for (const ni of this.currentUser.navItems) {
                this.initNavItem(ni);
            }
            this.currentUser.pathToModel = this.pathToModel(currentUser.modelToPath);

            this.gotUser = true;
            this.gettingUser = false;

            this.subjectUser.next(this.currentUser);
            this.postLoginMessage();

            if (goto) {
                this.router.navigateByUrl(goto)
            } else {
                // goto lost by oAuth... Let's try get there anyway...
                const returnMePath = window.localStorage.getItem(CurrentUserService.returnMe);
                if (returnMePath) {
                    const retO = JSON.parse(returnMePath);
                    if (retO['time'] && retO['time'] > new Date().getTime() - (60 * 15 * 1000)) {
                        window.localStorage.removeItem(CurrentUserService.returnMe);
                        this.router.navigate([retO['path']]);
                    }
                }
            }
        }, error => {
            this.gettingUser = false;
            this.subjectUser.next(null);
            if (error.status === 503) {
                window.location.href = "https://clgsystems.ie/maintenance-mode?site=" + window.location.hostname + "&url=" + window.location.href;
            } else if (error.status !== 401) {
                console.error('Error occured retrieving current user details', error);
            }

        });
    }

    postLoginMessage() {
        this.broadcastChannel.postMessage({
            from: this.thisInstance, teamTo: this.currentUser.current_team_id,
            roleId: this.currentUser.currentRoleId, roleName: this.currentUser.currentRole?.name,
            userId: this.currentUser.id
        });
    }

    /*
    confirmStillLoggedInAsUser() {
        const INTERVAL = 60000;
        const closeTimer$ = new Subject<unknown>();
        let times = 0;
        const maxTimes = 60;
        const r$ = this.router.events.subscribe( () => times = 0);

        timer(0, INTERVAL).pipe(
            switchMap( () => this.getCurrentUserCheck()),
            takeUntil(closeTimer$)
        ).subscribe({
            next: (res: {user: uuid, team: uuid}) => {
                if (times++ > maxTimes) {
                    // We need to stop, otherwise we'll keep the server session alive forever!
                    r$.unsubscribe();
                    closeTimer$.next();
                    this.cds.alert('Session Timeout', "Session timedout - you need to reload this page", () => window.location.reload());
                }
                if (res.user !== this.currentUser.id || res.team !== this.currentTeam.id) {
                    closeTimer$.next();

                    const title = $localize`Team Switched`;
                    const msg = $localize`It seems you have been switched into ${res.team} in another tab.
                        Reload this tab now? Switch back into ${this.currentTeam.id} in another tab and then say no if you want to stay on this page`
                    const choice = {
                        title, msg, options: [
                            { name: $localize`Yes reload as ${res.team}`, action: () => window.location.reload() },
                            { name: $localize`No`, action: () => this.confirmStillLoggedInAsUser()}
                        ]
                    } as CDSData;
                    this.cds.openChoice(choice, false);
                }
            },
            error: (error) => {
                console.log("Cannot check current user logged in", error);
                closeTimer$.next();
            }
        })
    }
    */

    getCurrentOmc(teamId: uuid): Omc {
        if (this.currentUser?.inAgency) {
            const ao = this.currentUser.inAgency.availableOmcs;
            if (ao && ao.length > 0) {
                const omc = ao.find( o => o.omcTeamId === teamId);
                if (omc) {
                    return omc;
                }
            }
        } else if (this.currentUser?.inOmc && this.currentUser.current_team_id === teamId) {
            return this.currentUser.inOmc;
        }
        console.warn({cu: this.currentUser, teamId});
        alert('Not able to get OMC Details for current user - Sorry - You should report this to support@ouromc.ie');
        return new Omc();
    }

    getCurrentUserCheck() {
        return this.http.get(AbstractHttpService.ajaxPath + 'currentUser/check');
    }

    logoutCurrentUser() {
        this.http.post('/api/logout', {}).subscribe(() => {
            this.httpGetCurrentUser();
            // TODO this.gotUser = false;
            this.router.navigate(['/']);
            this.broadcastChannel.postMessage({
                from: this.thisInstance, teamTo: null, roleId: null, userId: null, roleName: null
            });
            this.ourAppTracker.reloadPage();
        }, error => console.log('Logout Error', error));
    }

    switchTeamRole(pur: PersonUnitRole) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.http.put(AbstractHttpService.ajaxPath + 'user/teams', { positionId: pur.id }).subscribe(
            (newPur: PersonUnitRole) => {
                this.broadcastChannel.postMessage({
                    from: this.thisInstance,
                    teamTo: pur.teamId, positionTo: pur.id, roleId: newPur.roleId, roleName: newPur.role?.name,
                    userId: this.currentUser.id
                });
                this.router.navigate(['/']).then(() => this.ourAppTracker.reloadPage());
            },
            error => {
                console.error('Failed to switch teams', error);
                const msg = $localize`Unable to switch to team ${pur?.team?.name} (${pur?.role?.name})`;
                this.messageService.show(new MsgDef(msg, 'fail'));
            }
        );
    }

    switchTeamOmc(omc: Omc) {
        this.http.put(AbstractHttpService.ajaxPath + 'user/teams', { teamId: omc.omcTeamId }).subscribe(
            (response: {teamId: uuid, roleId: uuid, roleName: string}) => {
                this.broadcastChannel.postMessage({
                    from: this.thisInstance, teamTo: omc.omcTeamId, omcName: omc.name,
                    roleId: response.roleId, roleName: response.roleName,
                    userId: this.currentUser.id
                });
                this.router.navigate(['/']).then(() => this.ourAppTracker.reloadPage());
            },
            error => {
                console.error('Failed to switch teams', error);
                const msg = $localize`Unable to switch to team ${omc?.name}`;
                this.messageService.show(new MsgDef(msg, 'fail'));
            }
        );
    }

    getDefaultBCodes() {
        if (this.currentTeam && !this.currentTeam.defaultBCodes) {
            this.http.get(AbstractHttpService.ajaxPath + 'currentUser/defaultBCodes').subscribe(
                (dbc: DefaultBCode[]) => {
                    this.currentTeam.defaultBCodes = dbc;
                    this.subjectDefaultBCodes.next(dbc);
                }
            )
        }
        return this.subjectDefaultBCodes.asObservable();
    }

    getDefaultBCode(type: DefaultBCodeType) {
        if (!this.currentTeam.defaultBCodes) {
            console.error('No default BCodes, Are youu sure getDefaultBCodes was exceuted to get them?');
        }
        return this.currentTeam.defaultBCodes.find(o => o.type === type);
    }

    public isUber() {
        if (this.currentUser?.email === 'greg@coburn.ie') {
            return true;
        }
        return false;
    }

    getCurrentUser(): Observable<User | null> {
        return this.subjectUser.asObservable();
    }

    gettingCurrentUserNow() {
        this.gettingUser = true;
    }

    static returnMeTo(to: string) {
        window.localStorage.setItem(CurrentUserService.returnMe, JSON.stringify({ path: to, time: new Date().getTime() }));
    }

}
