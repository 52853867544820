@if (dialogRef) {
    <app-dialog-banner text="{{config.title}} {{config.mode === 'list' ? '' : ' > ' + config.getItemTitle()}}" [dialogRef]="dialogRef"></app-dialog-banner>
}
<div style="margin:5px 10px 10px 5px" formPageHolder>
  @if (!dialogRef) {
    <app-table-page-header (tableSettings)="table?.tableSettings()"
      (toggleCharts)="toggleCharts()"
      (printTable)="printTable()"
      [data]="data" [config]="config"
      [mode]="mode"
      >
    </app-table-page-header>
  }
  @if ((drillDown && config.mode !== 'list') || config.mode === 'new') {
    <app-form [config]="config" [dialogRef]="dialogRef">
        <ng-content></ng-content>
    </app-form>
  }
  @if (config?.mode === 'list') {
    <app-filter [config]="config" (navigateEvent)="navigateUrl()"></app-filter>
  }
  @if (showCharts()) {
    <app-chart-analysis [config]="config"></app-chart-analysis>
  }
  @if (config.mode === 'list') {
    <app-table [config]="config" [drilldown]="drillDown"></app-table>
  }
</div>
