/*
* Copyright Gregory Coburn 2020-2024, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { Attachment } from './attachment';

export type MergeType = 'Billing'|'Unit'|'Person'|'Invoice';
export type MergeStatus = 'New'|'Submitted'|'Working'|'Complete';
export type MergeDestination = 'PDF'|'Email'|'NoEmailPDF';

export const MailStatuses : AbstractObject[] = [
    { id: 0, name: 'Sent' }, { id: 2, name: 'Logged (Not Sent)' },
    { id: 4, name: 'Not Sent (opt out)' }, { id: 8, name: 'Not Sent (Prev Bounce)' },
    { id: 16, name: 'Bounced' }, { id: 32, name: 'Complaint' }, { id: 64, name: 'Failed' }
]

export class MailItem extends AbstractObject {
    fromAddress: string;
    toAddress: string;
    subject: string;
    content: string;
    size: number;
    responseCode: number;
    mailStatus: uuid;
}
export class MailMerge extends AbstractObject {
    content: string;
    modelId: uuid;
    params: {[k:string]:string|number|boolean};
    type: MergeType;
    status?: MergeStatus;
    destination: MergeDestination;

    todo?: number;
    done?: number;

    createdById?: uuid;
    updatedById?: uuid;

    attachments?: Attachment[] = [];

    unitEmails? = 0;
    unitPDFs? = 0;
    emails? = 0;

}
